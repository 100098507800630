.survey-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}

.survey-popup-inner {
  position: relative;
  padding: 48px;
  width: 100%;
  max-width: 640px;
  background: #ffffff;
  align-items: center;
  border-radius: 40px;
}

.survey-inner-icon {
  position: absolute;
  width: 64px;
  height: 64px;
  left: 292px;
  top: 48px;
}

.survey-inner-data {
  position: relative;
  width: 536px;
  left: calc(50% - 536px / 2 + 1px);
  top: 106px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  height: 60px;
  text-align: center;
  padding: 0 30px;
  color: #3b3c3d;
}

.survey-close-btn {
  position: absolute;
  top: 40px;
  right: 40px;
  height: 24px;
  width: 24px;
}

.survey-link-btn {
  display: block;
  width: 427px;
  height: 56px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  margin: 177px auto 32px auto;
  padding: 0;
  font-family: "Open Sans";
}

.survey-link-btn:hover {
  background: #2188c6;
}

@media (max-width: 768px) {
  .survey-popup-inner {
    max-width: 340px;
    padding: 22px;
    border-radius: 21.21px;
  }

  .survey-inner-icon {
    position: absolute;
    width: 30px;
    height: 30px;
    left: calc(50% - 30px / 2);
    top: 31px;
  }

  .survey-inner-data {
    position: relative;
    width: 288px;
    left: calc(50% - 288px / 2 + 1px);
    top: 62px;
    font-size: 13px;
    line-height: 18px;
    height: 36px;
    padding: 0;
  }

  .survey-close-btn {
    top: 22px;
    right: 22px;
    height: 13px;
    width: 13px;
  }

  .survey-link-btn {
    width: 274px;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin: 90px auto 9px auto;
  }

  .survey-steps {
    position: relative;
    margin-left: 15px;
    max-width: 274px;
    padding-right: 15px;
    font-size: 12px;
  }
}
